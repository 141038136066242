import React from "react";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { thermalPrint } from "../PrinterService";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import OTPInput, { ResendOTP } from "otp-input-react";
function RightContent(props) {
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div
        className={"AdminDashboard-tabs-rightside-content-left-side-delivered"}
      >
        {props.data.status === "order cancelled" ? (
          <Tooltip
            placement="bottom-end"
            title={`${
              props?.data?.cancellationReason?.unavailableItems?.length
                ? `unavailable items : ${props.data.cancellationReason.unavailableItems}`
                : ""
            }`}
          >
            <div className="Cancelled_page_information_div">
              <p> Cancellation reason: </p>
              <p>{props?.data?.cancellationReason?.text}</p>
              {props?.data?.cancellationReason?.unavailableItems?.length ? (
                <ArrowDropDownIcon />
              ) : null}
            </div>
          </Tooltip>
        ) : null}
        {props.data?.orderData?.length > 0 && (
          <div className="AdminDashboard-tabs-rightside-content-left-side-upper-div">
            <div
              className={
                props.data.status === "InProgress"
                  ? ""
                  : props.data.status === "Delivered"
                  ? "right-icon-class-wrapper"
                  : "wrong-icon-class-wrapper"
              }
            >
              {props.data.status === "InProgress" ? (
                ""
              ) : props.data.status === "Delivered" ? (
                <CheckCircleOutlineSharpIcon />
              ) : (
                <CancelOutlinedIcon />
              )}
            </div>
            <div className="AdminDashboard-tabs-rightside-content-inner-divs pin_div_wrapper">
              {props?.data?.pickPin && (
                <>
                  <h3 className="AdminDashboard-leftside-upper-div-h3 pin_h3">
                    PIN to access order
                    <OTPInput
                      inputClassName="readonly-input"
                      onKeyDown={handleKeyDown}
                      value={props?.data?.pickPin}
                      OTPLength={props?.data?.pickPin?.length ?? 4}
                      otpType="number"
                      disabled={false}
                      className="Congratulation_page_otp_input"
                    />
                  </h3>
                  {/* <strong>{props?.data?.pin}</strong> */}
                </>
              )}
            </div>
            <p className="AdminDashboard-leftside-upper-div-p">
              {" "}
              Order Date :{"  "}
              {new Date(props?.data?.createdAt)
                .toLocaleString("en-GB", {
                  // weekday: "short",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  timeZoneName: "short",
                  timeZone: props?.storelistData[0]?.timezone,
                })
                ?.replace(",", " ")}{" "}
              {", "}{" "}
              {new Date(props?.data?.createdAt).toLocaleTimeString("en-US", {
                // hour: "numeric",
                // minute: "numeric",
                // second: "numeric",
                // timeZoneName: "short",
                timeZone: props?.storelistData[0]?.timezone,
              })}
            </p>
            <div className="AdminDasboard_tabs_wrapper">
              {props.data?.orderData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="AdminDashboard-tabs-rightside-content-inner-divs"
                  >
                    <span>
                      <h3 className="AdminDashboard-leftside-upper-div-h3">
                        {" "}
                        {item.name.includes("x1")
                          ? item.name.split("x1")[0]
                          : item.name}
                        <strong>X{item.quantity}</strong>
                      </h3>
                    </span>
                    <strong>
                      {" "}
                      {props.data.currency}
                      {(item.price * item.quantity).toFixed(2)}
                    </strong>
                  </div>
                );
              })}
            </div>
            <div className="AdminDasboard_tabs_total_wrapper">
              {props?.data?.tax && props?.data?.tax > 0 ? (
                <div className="AdminDashboard-tabs-rightside-content-inner-divs">
                  <h3 className="AdminDashboard-leftside-upper-div-h3">
                    Sales Tax
                  </h3>
                  <strong>
                    {" "}
                    {props.data.currency}
                    {props.data.tax}
                  </strong>
                </div>
              ) : null}
              {props?.storelistData[0]?.showDeliveryFee &&
              props?.data?.deliveryfee &&
              props?.data?.deliveryfee > 0 ? (
                <div className="AdminDashboard-tabs-rightside-content-inner-divs">
                  <h3 className="AdminDashboard-leftside-upper-div-h3">
                    Delivery Fee
                  </h3>
                  <strong>
                    {" "}
                    {props.data.currency}
                    {props.data.deliveryfee.toFixed(2)}
                  </strong>
                </div>
              ) : null}
              <div className="AdminDashboard-tabs-rightside-content-inner-divs">
                <h3 className="AdminDashboard-leftside-upper-div-h3">
                  Subtotal
                </h3>
                <strong>
                  {" "}
                  {props.data.currency}{" "}
                  {(
                    parseFloat(props.data.cartTotal) +
                    (props?.data?.deliveryfee
                      ? parseFloat(props.data.deliveryfee)
                      : parseFloat(0)) +
                    (props?.data?.tax
                      ? parseFloat(props.data.tax)
                      : parseFloat(0))
                  ).toFixed(2)}
                </strong>
              </div>
              {props?.data?.discount && (
                <div className="AdminDashboard-tabs-rightside-content-inner-divs">
                  <h3 className="AdminDashboard-leftside-upper-div-h3">
                    Discount
                  </h3>
                  <strong>
                    {" "}
                    {props.data.currency}{" "}
                    {parseFloat(props.data.discount).toFixed(2)}
                  </strong>
                </div>
              )}
              {props?.data?.discountCode && (
                <div className="AdminDashboard-tabs-rightside-content-inner-divs">
                  <h3 className="AdminDashboard-leftside-upper-div-h3">
                    Discount Code
                  </h3>
                  <strong> {props?.data?.discountCode}</strong>
                </div>
              )}
              <div className="AdminDashboard-tabs-rightside-content-inner-divs-last">
                <span>Total</span>
                <strong>
                  {" "}
                  {props.data.currency}
                  {(
                    parseFloat(props?.data?.cartTotal) +
                    (props?.data?.deliveryfee
                      ? parseFloat(props?.data?.deliveryfee)
                      : parseFloat(0)) +
                    (props?.data?.tax
                      ? parseFloat(props.data.tax)
                      : parseFloat(0)) -
                    (props?.data?.discount
                      ? parseFloat(props.data.discount)
                      : parseFloat(0))
                  ).toFixed(2)}
                </strong>
              </div>
            </div>
          </div>
        )}
        <div className="AdminDashboard-tabs-rightside-content-left-side-lower-div">
          <div className="AdminDashboard-tabs-rightside-content-left-side-upper-div">
            <h3 className="AdminDashboard-leftside-upper-div-h3">
              {" "}
              Customer Details
            </h3>

            {props.data?.orderData?.length <= 0 && (
              <div className="AdminDashboard-tabs-rightside-content-inner-divs pin_div_wrapper">
                {props?.data?.pickPin && (
                  <>
                    {" "}
                    <h3 className="AdminDashboard-leftside-upper-div-h3 pin_h3">
                      PIN to access order
                      <OTPInput
                        inputClassName="readonly-input"
                        onKeyDown={handleKeyDown}
                        value={props?.data?.pickPin}
                        OTPLength={props?.data?.pickPin?.length ?? 4}
                        otpType="number"
                        disabled={false}
                        className="Congratulation_page_otp_input"
                      />
                    </h3>
                    {/* <strong>{props?.data?.pin}</strong> */}
                  </>
                )}
              </div>
            )}

            <div className="AdminDashboard-tabs-rightside-content-inner-divs">
              <p className="">Name </p>
              <strong>
                {" "}
                <p style={{ fontSize: "15px" }}> {props.data.userName}</p>
              </strong>
            </div>
            <div className="AdminDashboard-tabs-rightside-content-inner-divs">
              <p className="">Contact Number</p>
              <strong>
                {" "}
                <p style={{ fontSize: "15px" }}>+ {props.data.mobileNumber}</p>
              </strong>
            </div>
            <div className="AdminDashboard-tabs-rightside-content-inner-divs">
              <p className="">Delivery Location </p>
              <strong>
                {" "}
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  {props.data?.drop?.locationName &&
                    Object.values(props.data?.drop?.locationName)[0]}
                </p>
              </strong>
            </div>
            {props.data?.orderData?.length > 0 && (
              <div className="Print_receipt_div">
                <p
                  className="flex items-center justify-between"
                  onClick={() =>
                    thermalPrint(
                      props.data,
                      props.storelistData,
                      props?.storelistData[0]?.timezone
                    )
                  }
                >
                  <img src="/assets/images/printer_icon.svg" alt="" />
                  Print Receipt{" "}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <img
        id="image"
        src="./poweredby.png"
        alt="logo"
        style={{
          position: "absolute",
          top: "0",
          visibility: "hidden",
        }}
      />
    </>
  );
}

export default RightContent;
